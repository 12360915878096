<template>
  <v-row v-if="selectedTripRequirements.length > 0" class="d-flex">
    <v-col
      v-for="(selectedTripRequirement,
      selectedTripRequirementIndex) in selectedTripRequirements"
      :key="`trip-${tripIndex}-requirement-${selectedTripRequirementIndex}`"
    >
      <CRIcon
        :key="`trip-${tripIndex}-requirement-icon-${selectedTripRequirementIndex}`"
        class="cursor-default margin-r-1 requirement-icon"
        style="vertical-align: middle"
      >
        {{ selectedTripRequirement.icon }}
      </CRIcon>
      {{ selectedTripRequirement.title }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'TripRequirements',
  props: {
    trip: {
      type: Object,
      default: null,
    },
    tripIndex: {
      type: Number,
      default: null,
    },
    tripCount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      requirementsIconMap: {
        vehicleNeededEntireTrip: {
          icon: 'bus_alert_warn',
          title: 'Vehicle stays on-site',
          key: 'vehicle_needed_entire_trip',
        },
        ada: {
          icon: 'accessible',
          title: 'ADA',
          key: 'ada',
        },
        spab: {
          icon: 'spab_2',
          title: 'SPAB',
          key: 'spab',
        },
      },
    }
  },
  computed: {
    selectedTripRequirements() {
      const tripRequirements = []
      if (this.trip?.ada) {
        tripRequirements.push(this.requirementsIconMap.ada)
      }
      if (this.trip?.spab) {
        tripRequirements.push(this.requirementsIconMap.spab)
      }
      if (this.trip?.vehicleNeededEntireTrip && this.tripCount < 2) {
        tripRequirements.push(this.requirementsIconMap.vehicleNeededEntireTrip)
      }
      return tripRequirements
    },
  },
}
</script>
<style lang="scss">
.requirement-icon {
  color: $gray-subtle-text-2 !important;
}
</style>
