<template>
  <v-card class="padding-a-5" style="box-shadow: none !important; border-radius: 16px;">
    <v-tabs
      v-model="currentTripIndex"
      :style="
        trips.length <= 1
          ? 'display: none;'
          : `border-bottom: 1px solid ${$cr.theme.borderGray}`
      "
      class="margin-b-3"
      slider-color="#00a6f2"
    >
      <v-tab
        v-for="(trip, tripIndex) in trips"
        :id="`trip-collections-card-tab-${tripIndex}`"
        :key="`trip-info-charterup-tab-${tripIndex}`"
      >
        {{ trip.routeName || `Trip ${tripIndex + 1}` }}
      </v-tab>
    </v-tabs>

    <div class="card-text">
      <TripRequirements
        :trip="currentTrip"
        :trip-index="currentTripIndex"
        :trip-count="trips.length"
      />
      <template v-for="(trip, tripIndex) in trips">
        <TripInfo
          v-show="currentTripIndex === tripIndex"
          :id="`trip-collection-${tripIndex}`"
          :key="`trip-info-charterup-${tripIndex}`"
          :style="
            trips.length <= 1
              ? map
                ? ''
                : '`border-top: 1px solid ${$cr.theme.borderGray};`'
              : ''
          "
          :trip="trip"
          :created-on="createdOn"
          :map="map"
          :itinerary="itinerary"
          :customer-notes="customerNotes"
          :quote-confirmation="quoteConfirmation"
          :current-trip="currentTripIndex"
          :trip-index="tripIndex"
        />
      </template>
    </div>
  </v-card>
</template>

<script>
import TripInfo from '@/components/charterup/TripInfo.vue'
import TripRequirements from '@/components/charterup/TripRequirements.vue'

export default {
  components: {
    TripInfo,
    TripRequirements,
  },
  props: {
    trips: {
      type: Array,
      default: () => [],
    },
    createdOn: {
      type: String,
      default: null,
    },
    map: {
      type: Boolean,
    },
    customerNotes: {
      type: Boolean,
    },
    itinerary: {
      type: Boolean,
    },
    quoteConfirmation: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentTripIndex: 0,
    }
  },
  computed: {
    currentTrip() {
      return this.trips?.[this.currentTripIndex]
    },
  },
  mounted() {
    this.currentTripIndex = 0
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-tabs__div {
  font-weight: 600;

  a {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>
