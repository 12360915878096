var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"timeline-container"},[_c('v-timeline',{staticClass:"trip-itinerary",attrs:{"align-top":"","dense":"","left":""}},_vm._l((_vm.sortedStops),function(stop,index){return _c('v-timeline-item',{key:stop.id,attrs:{"color":"white","small":""}},[_c('h4',{attrs:{"id":`trip-info-itinerary-stop-${index}-name`}},[_vm._v(" "+_vm._s(stop.address.title || _vm.addressPretty(stop))+" ")]),(stop.address.title)?_c('div',{staticClass:"address-title",staticStyle:{"font-weight":"400","font-family":"'Lato', sans-serif"},attrs:{"id":`trip-info-itinerary-stop-${index}-address`}},[_vm._v(" "+_vm._s(_vm.addressPretty(stop))+" ")]):_vm._e(),_c('div',[(
            stop.dropoffDatetime
              ? stop.dropoffDatetime
              : stop.dropoff_datetime
          )?_c('div',{staticClass:"arrival-departure address-title",staticStyle:{"font-weight":"400","font-family":"'Lato', sans-serif"},attrs:{"id":`trip-info-itinerary-stop-${index}-pickup-time`}},[_vm._v(" Estimated arrival: "+_vm._s(_vm.formatDisplayTime(stop.dropoffDatetime, stop.address.timeZone))+" ")]):_vm._e()]),_c('div',[(
            stop.pickupDatetime ? stop.pickupDatetime : stop.pickup_datetime
          )?_c('div',{staticClass:"arrival-departure address-title",attrs:{"id":`trip-info-itinerary-stop-${index}-pickup-time`}},[_vm._v(" Pickup time: "+_vm._s(_vm.formatDisplayTime(stop.pickupDatetime, stop.address.timeZone))+" ")]):_vm._e()]),_c('div',{staticStyle:{"height":"20px"}})])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }