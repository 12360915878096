
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { gmapApi } from 'vue2-google-maps';
import { mapStyles } from '@/utils/mapStyles';
import markerSvg from '@/assets/images/marker.svg'

interface Stop {
  address: {
    lat: number
    lng: number
  };
}

@Component({})
export default class MapComponent extends Vue {
  @Prop({ type: Array, default: () => [] }) stops!: Stop[];

  map: any = null
  center: { lat: number; lng: number } = { lat: 10, lng: 10 }
  bounds: any = null
  options: any = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUI: true,
    styles: [
      ...mapStyles,
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  }

  clusterOptions: any = {
    styles: [
      {
        textColor: 'white',
        url:
          'https://raw.githubusercontent.com/googlemaps/js-marker-clusterer/gh-pages/images/m1.png',
        height: 55,
        width: 55,
        textSize: 20,
      },
    ],
    maxZoom: 20,
  }

  markerOptions: any = {
    visible: false,
    icon: {
      url: markerSvg
    },
  }

  get google(): any {
    return gmapApi()
  }

  @Watch('stops', { immediate: true, deep: true })
  async onStopsChanged(): Promise<void> {
    await this.loadData()
    await this.plotRoute()
  }

  async mounted(): Promise<void> {
    await this.loadData()
    await this.plotRoute()
  }

  async loadData(): Promise<void> {
    // If the map is not loaded yet, wait for it to load
    if (this.map == null && this.$refs?.mapRef != null) {
      const mp = await (this.$refs.mapRef as any).$mapPromise.then((mp: any) => mp);
      this.map = mp;
    }

    if (this.map == null || this.google?.maps == null) {
      return
    }

    const bounds = new this.google.maps.LatLngBounds()
    this.stops.forEach((stop) => {
      bounds.extend({ lat: stop.address.lat, lng: stop.address.lng });
    });
    this.map.fitBounds(bounds);

  }

  async plotRoute(): Promise<void> {
    if (this.map == null || this.google?.maps == null) {
      return
    }

    const directionsService = new this.google.maps.DirectionsService()
    const directionsRenderer = new this.google.maps.DirectionsRenderer()
    directionsRenderer.setOptions({
      map: this.map,
      markerOptions: this.markerOptions,
      polylineOptions: {
        strokeColor: '#434343',
        strokeOpacity: 0.6,
        strokeWeight: 5,
      },
    });

    const waypoints = this.stops.map((stop) => {
      return {
        location: new this.google.maps.LatLng(Number(stop.address.lat), Number(stop.address.lng)),
        stopover: true,
      };
    });

    waypoints.map((waypoint) => {
      return new this.google.maps.Marker({
        position: waypoint.location,
        map: this.map,
        icon: this.markerOptions.icon,
      });
    });

    const origin = waypoints.shift()?.location;
    let destination = waypoints.pop()?.location;

    if (origin && destination && origin.equals(destination)) {
      destination = waypoints.pop()?.location;
    }

    if (origin && destination) {
      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: this.google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(response);
          } else {
            throw new Error('Directions request failed due to ' + status);
          }
        }
      );
    }
  }
}
