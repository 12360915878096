<template>
  <div>
    <TripMap v-if="map" :stops="tripAllStops" />
    <div v-show="!$cr.breakpoint.smAndUp && itinerary">
      <v-layout>
        <v-flex>
          <h3>
            {{ cityPretty(firstStop) }}
            <v-icon>arrow_right_alt</v-icon>
            {{ cityPretty(secondStop) }}
          </h3>
          <v-dialog
            v-model="imageModal"
            :content-class="$cr.breakpoint.xs ? 'fullscreen-dialog' : ''"
            width="600"
            :fullscreen="$cr.breakpoint.xs"
          >
            <template #activator="{ on }">
              <a v-on="on">View Itinerary</a>
            </template>
            <div style="background-color: white; height: 100%">
              <v-toolbar
                color="primary"
                flat
                style="padding-top: 12px; padding-bottom: 12px; height: 80px"
              >
                <v-toolbar-title>
                  <h2 style="color: white">Itinerary</h2>
                </v-toolbar-title>
                <v-spacer />
                <v-icon
                  :id="`${id}-trip-info-close`"
                  large
                  color="white"
                  @click="imageModal = false"
                >
                  close
                </v-icon>
              </v-toolbar>
              <v-card-text style="margin-top: 24px">
                <TripInfoItinerary :stops="tripAllStops" />
              </v-card-text>
            </div>
          </v-dialog>
        </v-flex>
      </v-layout>
      <v-divider style="margin-top: 10px" />
    </div>

    <article class="py-3">
      <v-layout
        no-gutters
        class="trip-info-stats"
        justify-space-between
        align-start
        :style="`
          padding-top: 7px;
          padding-bottom: 10px;
          border-bottom: 1px solid ${$cr.theme.borderGray};
        `"
      >
        <div style="display: flex">
          <div>
            <span
              v-for="(vehicle, vehicleIndex) in tripVehicles"
              :id="`trip-info-vehicle-${vehicleIndex}-content`"
              :key="vehicleIndex"
              style="text-align: left"
            >
              {{ vehiclesPretty(vehicle) }}
              <br />
            </span>
          </div>
        </div>
        <div id="trip-info-passenger-count" style="display: flex">
          {{ tripPassengerCount }} Passengers
        </div>
        <div style="display: flex">
          {{ tripType }}
        </div>
      </v-layout>
    </article>

    <article
      v-if="$cr.breakpoint.mdAndUp && customerNotes && tripDescription"
      style="margin-bottom: 20px"
    >
      <h4
        style="
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0;
          line-height: normal;
        "
      >
        Contact Notes
      </h4>
      {{ customerNotesText }}
      <button
        v-if="tripDescription && tripDescription.length > 111"
        class="more-btn"
        @click="() => (customerNotesOpen = !customerNotesOpen)"
      >
        {{ customerNotesOpen ? 'Less' : 'More' }}
      </button>
    </article>

    <article
      v-if="itinerary && $cr.breakpoint.smAndUp"
      :ref="`timeline-wrapper-${tripIndex}`"
      class="timeline-wrapper"
      style="padding: 0"
      :class="quoteConfirmation ? 'scrollable' : ''"
    >
      <v-layout dense>
        <v-flex>
          <TripInfoItinerary :stops="tripAllStops" />
          <div
            v-if="quoteConfirmation && showScrollIndicator"
            class="scroll-indicator"
            :style="`opacity: ${scrollIndicatorOpacity};`"
          >
            <p class="scroll-indicator__text">(scroll to see full itinerary)</p>
          </div>
        </v-flex>
      </v-layout>
    </article>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { pluralize, truncate } from '@/utils/string'

import TripInfoItinerary from '@/components/charterup/TripInfoItinerary.vue'
import TripMap from '@/components/charterup/TripMap.vue'

export default {
  components: {
    TripInfoItinerary,
    TripMap,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    trip: {
      type: Object,
      default: () => {},
    },
    total: {
      type: Number,
      default: null,
    },
    createdOn: {
      type: String,
      default: null,
    },
    companyName: {
      type: String,
      default: null,
    },
    to: {
      type: Object,
      default: null,
    },
    itinerary: {
      type: Boolean,
    },
    customerNotes: {
      type: Boolean,
    },
    map: {
      type: Boolean,
    },
    quoteConfirmation: {
      type: Boolean,
    },
    tripIndex: {
      type: Number,
      required: true,
    },
    currentTrip: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      imageModal: false,
      customerNotesOpen: false,
      scrollIndicatorOpacity: 1,
      showScrollIndicator: false,
    }
  },
  computed: {
    customerNotesText() {
      if (!this.customerNotes || !this.tripDescription) {
        return ''
      }
      return this.customerNotesOpen
        ? this.tripDescription
        : truncate(this.tripDescription, 111, true)
    },
    firstStop() {
      return this.trip?.stops?.[0]
    },
    secondStop() {
      return this.trip?.stops?.[1]
    },
    stopLength() {
      return this.trip?.stops?.length
    },
    lastStop() {
      return this.trip?.stops?.[this.stopLength - 1]
    },
    tripAllStops() {
      return this.trip?.stops
    },
    tripDescription() {
      return this.trip?.description
    },
    tripCompanyName() {
      return this.trip?.companyName
    },
    tripPassengerCount() {
      return this.trip?.passengerCount
    },
    tripTotal() {
      return this.total
    },
    tripCharges() {
      return this.trip?.charges
    },
    tripVehicles() {
      return this.trip?.vehicles
    },
    tripType() {
      return this.trip?.tripType?.label
    },
    tripDateRange() {
      let startDay = this.firstStop.pickupDatetime
      let endDay =
        this.lastStop?.dropoffDatetime || this.lastStop?.pickupDatetime
      try {
        startDay = DateTime.fromISO(startDay, {
          zone: this.firstStop.address.timeZone,
        }).toFormat('LL/dd')
      } catch (err) {
        return ''
      }
      if (endDay) {
        try {
          endDay = DateTime.fromISO(endDay, {
            zone: this.lastStop.address.timeZone,
          }).toFormat('LL/dd')
        } catch (err) {
          endDay = null
        }
      }
      return startDay === endDay || !endDay
        ? startDay
        : `${startDay} -  ${endDay}`
    },
  },
  watch: {
    currentTrip() {
      if (this.quoteConfirmation && this.tripIndex === this.currentTrip) {
        this.setBoxHeight()
        this.$nextTick(() => {
          this.showScrollIndicator =
            this.$refs[`timeline-wrapper-${this.tripIndex}`].scrollHeight >
            this.$refs[`timeline-wrapper-${this.tripIndex}`].clientHeight
          this.scrollIndicatorOpacity = 1
        })
      }
    },
    customerNotesOpen() {
      if (this.quoteConfirmation) {
        this.$nextTick(() => {
          this.setBoxHeight()
        })
      }
    },
    '$cr.breakpoint.name'(newVal, oldVal) {
      if (
        (newVal === 'lg' || newVal === 'xl') &&
        (oldVal === 'md' || oldVal === 'sm' || oldVal === 'xs')
      ) {
        this.$nextTick(() => this.setBoxHeight())
      }
    },
  },
  mounted() {
    if (this.quoteConfirmation) {
      this.$refs[`timeline-wrapper-${this.tripIndex}`].addEventListener(
        'scroll',
        () => {
          const scrollPos = this.$refs[`timeline-wrapper-${this.tripIndex}`]
            .scrollTop
          this.scrollIndicatorOpacity =
            scrollPos > 100 ? 0 : 1 - scrollPos / 100
        }
      )

      this.setBoxHeight()
      this.showScrollIndicator =
        this.$refs[`timeline-wrapper-${this.tripIndex}`].scrollHeight >
        this.$refs[`timeline-wrapper-${this.tripIndex}`].clientHeight
    }
  },
  methods: {
    onClick() {
      this.$router.push(this.to)
    },
    vehiclesPretty(vehicle) {
      const label = pluralize(vehicle?.quantity, vehicle?.vehicleType?.label)
      return `${vehicle?.quantity} ${label}`
    },
    cityPretty(stop) {
      const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''
      return `${city}${stop?.address?.state}`
    },
    setBoxHeight() {
      const yPosition = this.$refs[
        `timeline-wrapper-${this.tripIndex}`
      ].getBoundingClientRect().top
      const footerHeight = document.querySelector('.confirm-footer')
        .clientHeight
      this.$refs[
        `timeline-wrapper-${this.tripIndex}`
      ].style.height = `calc(100vh - ${
        Math.abs(yPosition) + Math.abs(footerHeight)
      }px)`
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  width: 20px;
}

.trip-info-stats {
  svg {
    color: #bbbbbb;
    margin-right: 10px !important;
  }

  .icon-center {
    display: flex;
    align-items: center;
  }
}

.more-btn {
  display: inline-block;
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: bold;
  color: $primary;
}

.timeline-wrapper {
  &.scrollable {
    min-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.scroll-indicator {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 65%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 90px;
  z-index: 20;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;

  .scroll-indicator__text {
    margin-top: 14px;
    font-weight: 700;
    color: $primary;
    text-shadow: 0px 0px 5px $white;
  }
}
</style>
