<template>
  <div class="timeline-container">
    <v-timeline align-top dense left class="trip-itinerary">
      <v-timeline-item
        v-for="(stop, index) in sortedStops"
        :key="stop.id"
        color="white"
        small
      >
        <h4 :id="`trip-info-itinerary-stop-${index}-name`">
          {{ stop.address.title || addressPretty(stop) }}
        </h4>
        <div
          v-if="stop.address.title"
          :id="`trip-info-itinerary-stop-${index}-address`"
          class="address-title"
          style="font-weight: 400; font-family: 'Lato', sans-serif"
        >
          {{ addressPretty(stop) }}
        </div>
        <div>
          <div
            v-if="
              stop.dropoffDatetime
                ? stop.dropoffDatetime
                : stop.dropoff_datetime
            "
            :id="`trip-info-itinerary-stop-${index}-pickup-time`"
            class="arrival-departure address-title"
            style="font-weight: 400; font-family: 'Lato', sans-serif"
          >
            Estimated arrival:
            {{ formatDisplayTime(stop.dropoffDatetime, stop.address.timeZone) }}
          </div>
        </div>
        <div>
          <div
            v-if="
              stop.pickupDatetime ? stop.pickupDatetime : stop.pickup_datetime
            "
            :id="`trip-info-itinerary-stop-${index}-pickup-time`"
            class="arrival-departure address-title"
          >
            Pickup time:
            {{ formatDisplayTime(stop.pickupDatetime, stop.address.timeZone) }}
          </div>
        </div>
        <div style="height: 20px" />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  props: {
    stops: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedStops() {
      let sorted = [...this.stops]
      sorted.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
      return sorted
    },
  },
  methods: {
    formatDisplayTime(time, timeZone) {
      return DateTime.fromISO(time, { zone: timeZone }).toFormat(
        'LL/dd/yy - hh:mm a'
      )
    },
    addressPretty(stop) {
      const street1 = `${stop?.address?.street1?.trim()} ` || ''
      const city = stop?.address?.city ? `${stop?.address?.city}, ` : ''
      const state = `${stop.address.state} ` || ''
      const postalCode = stop.address.postalCode || ''
      return `${street1}${city}${state}${postalCode}`
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-timeline:before {
  background-color: rgba(0, 0, 0, 0) !important;
  border-left: 2px dashed $gray-mid-light;
}

::v-deep .v-timeline-item__body {
  padding-left: 6px;
  font-family: 'Airbnb Cereal App Medium', 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-top: 11px;

  h4 {
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
  }
}

.address-title {
  font-weight: 400;
  font-family: 'Lato', sans-serif;
}

::v-deep .v-timeline-item__dot {
  height: 12px;
  width: 12px;
  background-color: #00a6f2 !important;
  margin-left: 6px;
  z-index: 10;
  box-shadow: none;

  .v-timeline-item__inner-dot {
    height: 8px;
    margin: 2px;
    width: 8px;
  }
}

.arrival-departure {
  color: $gray-medium-light !important;
}
</style>
