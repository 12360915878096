var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"padding-a-5",staticStyle:{"box-shadow":"none !important","border-radius":"16px"}},[_c('v-tabs',{staticClass:"margin-b-3",style:(_vm.trips.length <= 1
        ? 'display: none;'
        : `border-bottom: 1px solid ${_vm.$cr.theme.borderGray}`),attrs:{"slider-color":"#00a6f2"},model:{value:(_vm.currentTripIndex),callback:function ($$v) {_vm.currentTripIndex=$$v},expression:"currentTripIndex"}},_vm._l((_vm.trips),function(trip,tripIndex){return _c('v-tab',{key:`trip-info-charterup-tab-${tripIndex}`,attrs:{"id":`trip-collections-card-tab-${tripIndex}`}},[_vm._v(" "+_vm._s(trip.routeName || `Trip ${tripIndex + 1}`)+" ")])}),1),_c('div',{staticClass:"card-text"},[_c('TripRequirements',{attrs:{"trip":_vm.currentTrip,"trip-index":_vm.currentTripIndex,"trip-count":_vm.trips.length}}),_vm._l((_vm.trips),function(trip,tripIndex){return [_c('TripInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTripIndex === tripIndex),expression:"currentTripIndex === tripIndex"}],key:`trip-info-charterup-${tripIndex}`,style:(_vm.trips.length <= 1
            ? _vm.map
              ? ''
              : '`border-top: 1px solid ${$cr.theme.borderGray};`'
            : ''),attrs:{"id":`trip-collection-${tripIndex}`,"trip":trip,"created-on":_vm.createdOn,"map":_vm.map,"itinerary":_vm.itinerary,"customer-notes":_vm.customerNotes,"quote-confirmation":_vm.quoteConfirmation,"current-trip":_vm.currentTripIndex,"trip-index":tripIndex}})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }